import classNames from 'classnames';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';

interface CarrouselProps extends PropsWithChildren{
	align?: 'start' | 'end' | 'center',
	maxWidth?: number,
	title?: string,
	titlePlaceholder?: boolean,
	navigation?: boolean,
	action?: any,
}

var Carrousel = (props: CarrouselProps)=>{
	var scrollRef = useRef<HTMLDivElement>(null);

	var onScroll = ()=>{
		if(!scrollRef.current) return;
		var scrolled = scrollRef.current.scrollLeft, width = scrollRef.current.clientWidth;
	}
	
	return <div className='fr carrousel'>
		{(!!props.title || !!props.navigation) && (
			<div className="header">
				<div className={classNames("text", {
					placeholder: props.titlePlaceholder,
				})}>{props.title}</div>
				{props.navigation && (
					<div className="navigation">
						<i className="arrow left icon"></i>
						<i className="arrow right icon"></i>
					</div>
				)}
				{!!props.action && (
					<div className="action">
						{props.action}
					</div>
				)}
			</div>
		)}
		<div ref={scrollRef} className="scroll" onScroll={onScroll}>
			{props.children}
		</div>
	</div>
}

export default Carrousel;