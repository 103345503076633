import { Image } from 'react-frontier';
import { CDN_URL } from '../ConfigPublic';

import '../style/footer.scss'

var Footer = ()=>{
   return <div className='co footer'>
      <div className="flex">
         <div className="left">
         <Image src={`${CDN_URL}/static/logo/Logo_Black_Small.png`} alt='Logo' className="logo" height={45}/>
            <div className="sections">
               <div className="section">
                  <div className="header">ACERCA DE</div>
                  <a href="/ayuda?h=envios" className="item">Envíos</a>
                  <a href="/facturar" className="item">Facturación</a>
                  <a href="/terminos" className="item">Términos</a>
               </div>
               <div className="section">
                  <div className="header">PRODUCTOS</div>
                  <a href="/shop/18-chocolate-original" className="item">Chocolate original</a>
                  <a href="/shop/19-tabletas-de-chocolate" className="item">Tabletas</a>
                  <a href="/shop/23-fresas-cocoan" className="item">Fresas</a>
                  <a href="/shop/43-regalos-cocoan" className="item">Regalos</a>
                  <a href="/shop/34-merchandise" className="item">Merchandise</a>
               </div>
               <div className="section">
                  <div className="header">CONTÁCTANOS</div>
                  <a href="/visitanos" className="item">Ubicación</a>
                  <a href="/ayuda" className="item">Ayuda</a>
               </div>
               <div className="section">
                  <div className="header">SÍGUENOS</div>
                  <a href="https://www.facebook.com/cocoanchocolateria/" className="item">
                     <i className="facebook square icon"></i>
                     /cocoanchocolateria
                  </a>
                  <a href="https://www.instagram.com/cocoanchocolateria/" className="item">
                     <i className="instagram icon"></i>
                     @cocoanchocolateria
               	</a>
               </div>
            </div>
         </div>
         <Image src={`${CDN_URL}/static/chocolover.png`} className='chocolover' height={150}/>
   	</div>
   </div>
}

export default Footer;
