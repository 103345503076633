import React, { useEffect, useState } from 'react';
import { Button, Header, Message, Table } from 'react-frontier';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { NotFound, ProductImage } from '@cocoan/components';
import { Location, SetLoading } from '@cocoan/components/Classes';
import { Icon } from 'semantic-ui-react';
import { CDN_URL } from '../ConfigPublic';
import { addCommas } from '../UtilPublic';
import { useTitle } from '../HooksPublic';
import { usePixel } from '@cocoan/components/Hooks';
import API from '../APIPublic';
import moment from 'moment';

import '../style/public_order.scss';

interface Order{
	order_id: number,
	order_hash: string,
	owner_name: string,
	owner_last_name: string,
	owner_email: string,
	owner_phone: string,
	shipping_method: number,
	shipping_method_name: string,
	shipping_method_type: number,
	shipping_method_location_id: number,
	shipping_location_id: number,
	shipping_date: number,
	gift_name: string,
	gift_last_name: string,
	gift_message: string,
	gift_phone: string,
	shipping_cost: number,
	total: number,
	paid: boolean,
	shipped: boolean,
	prepared: boolean,
	date_created: number,
	shipping_location: Location,
	shipping_method_location: Location,
	items: {
		item_id: number,
		product_id: number,
		product_name: string,
		size: string,
		quantity: number,
		cost_single: number,
		cost: number
	}[],
	payments: {
		payment_id: number,
		payment_method: number,
		method_name: string,
		amount: number,
		paid: boolean,
	}[],
}

declare global {
	interface Window {
		gtag: (...args: any[]) => void;
	}
}

var PublicOrder = ()=>{
	var { hash } = useParams();
	var Pixel = usePixel();
	var navigate = useNavigate();
	var [order, setOrder] = useState<Order>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var { setTitle } = useTitle();

	useEffect(()=>{
		if(!hash || hash.length<3) return;
		API.getOrderData(hash).then(res=>{
			if(res.error) return setLoadError(res.message);
			setTitle(`Orden #CCN${res.data.order_id}`)
			setOrder(res.data);

			if(!res.data.paid && (!res.data.payments || res.data.payments.length==0 || !res.data.payments.find((a: any)=>!a.paid))){
				return navigate(`/checkout/${hash}`);
			}else{
				try{
					if(res.data && (moment().unix()-res.data.date_paid)<=(3600*2)){
						Pixel.purchase({
							content_ids: (res.data as Order).items.map(a=>a.product_id),
							content_name: `Orden #CCN${res.data.order_id}`,
							content_type: 'product',
							currency: 'MXN',
							num_items: (res.data as Order).items.length,
							value: res.data.total,
							contents: (res.data as Order).items.map(a=>({
								id: a.product_id,
								quantity: a.quantity
							}))
						}, `CCN-${res.data.order_id}`);
						window.gtag('event', 'conversion', {
							'send_to': 'AW-16713478676/IBBhCLXM8tgZEJTkzaE-',
							'value': res.data.total,
							'currency': 'MXN',
							'transaction_id': res.data.order_id
						});
					}
				}catch(e){}
			}
		}).catch(err=>{
			return setLoadError('Hubo un error inesperado cargando la orden (LCL-1)');
		});
	}, []);
	
	if(!hash || hash.length<3) return <NotFound />
	if(loadError) return <NotFound subtext={loadError} />
	if(!order) return <Header loading text='Cargando orden...' />

	var total_products = order.items.reduce((a,b)=>a+b.cost, 0);
	var pending_payment : Order["payments"][0] = null;
	if(!order.paid && order.payments && order.payments.length>0){
		var pp = order.payments.filter(a=>!a.paid);
		if(pp.length>0) pending_payment = pp[0];
	}

	if(!order.paid && !pending_payment) return null;
	
	return <div className="co public-order-screen">
		<Header text={order.paid ? '¡Gracias por tu compra!' : 'Confirmación de compra'} style={{ marginBottom: 20 }}/>
		<Message className='masthead' header={`Orden #CCN${order.order_id}`} centered headerStyle={{ fontSize: 30, lineHeight: '30px' }}>
			<Header text={order.paid ? '¡Gracias por tu compra chocolover!' : 'Pronto tendrás tus chocolates'} />
			<Icon name='check circle' size='massive' color={!order.paid ? 'yellow' : 'green'} style={{ margin: 0, marginTop: 10 }} />
		</Message>
		<Message className='summary'>
			<div className="label">{order.owner_name} {(order.owner_last_name || '')}</div>
			Orden creada el {moment.unix(order.date_created).format('DD/MMM/YY HH:mm')} <br />
			Total: <b>${addCommas(order.total)}</b> <br />
			Estatus de pago: <b style={{ color: order.paid ? 'black' : 'brown' }}>{!order.paid ? 'Pendiente de pago' : 'Pagada'}</b>

			<div className="label">Entrega</div>
			<b>{order.shipping_method_name}</b> <br />
			{!!order.shipping_date && <>
				Fecha de entrega: <b>{moment.unix(order.shipping_date).format('DD/MMM/YY')}</b>
			</>}

			{!!order.shipping_location && <>
				<div className="label">Dirección de entrega</div>
				{order.shipping_location.street} {order.shipping_location.exterior_number} {order.shipping_location.interior_number} <br />
				{order.shipping_location.neighborhood} <br />
				{order.shipping_location.city}, {order.shipping_location.zipcode} <br />
				{order.shipping_location.state}
			</>}

			{!!order.gift_name && order.gift_name.length>3 && <>
				<div className="label">Regalo</div>
				Para <b>{order.gift_name} {(order.gift_last_name || '')}</b> <Icon name='gift' />
				<br />
				<div className="gift-message">"{order.gift_message}"</div>
			</>}
		</Message>
		{!order.paid && (
			<Message type='info' centered>
				<div className="header">Pendiente de pago</div>
					Esta orden está pendiente de pago. Favor de realizar el pago de <b>{pending_payment?.method_name}</b>
				<br />
				<Button text='Ver ficha de pago' color='blue' style={{ marginTop: 15 }} iconName='pdf file' as={Link} to={`/order/${order.order_hash}/payment`} target='_blank' />
			</Message>
		)}
		<Header text='Productos' centered style={{ marginTop: 20 }} />
		<div className="co items">
			{order.items.map(a=>(
				<div className="item" key={`ordprd-${a.item_id}`}>
					<Link to={`/product/${a.product_id}`}>
						<ProductImage image={`${CDN_URL}/products/${a.product_id}/0.webp`} width={120} />
					</Link>
					<div className="info">
						<Link className="name" to={`/product/${a.product_id}`}>{a.product_name}</Link>
						<div className="size">{a.size}</div>
						<div className="cost">${addCommas(a.cost)}</div>
						<div className="quantity">Cantidad: <span className="number">{a.quantity}</span></div>
					</div>
				</div>
			))}
		</div>
		<Table
			className='basic totals'
			collapsingIndexes={[1]}
			data={[
				['Productos', `$${addCommas(total_products)}`],
				['Entrega', `$${addCommas(order.shipping_cost)}`],
				['TOTAL', `$${addCommas(order.total)}`],
			]}
		/>
		<div style={{ textAlign: 'center', marginTop: 50 }}>
			<Button text='Facturar compra' color='black' size='big' as={Link} to={`/facturar?o=${order.order_hash}`} />
		</div>
		{/* <div className='co options'>
			<div className='option'>
				<Icon name='question circle' size='huge'/>
				<div className='text'>Si tienes dudas de tu pedido</div>
				<Button text='haz click aqui' color='yellow' as={Link} to={`/ayuda`}/>
			</div>
			<div className='option'>
				<Icon name='file alternate' size='huge'/>
				<div className='text'>Para facturar tu compra</div>
				<Button text='haz click aqui' color='yellow' as={Link} to={`/facturar?o=${order.order_hash}`}/>
			</div>
      </div> */}
		{/* <div className='co footer'>
			<Header text='Síguenos en nuestras redes sociales' centered style={{ marginTop: 60, fontSize: 20 }} />
			<Link to={'https://www.instagram.com/cocoanchocolateria/?hl=en'}><Icon name='instagram' size='huge'/></Link>
			<Link to={'https://www.facebook.com/pages/Cocoan-Chocolateria/1489850551114449'}><Icon name='facebook' size='huge'/></Link>
			<Link to={'mailto:hola@cocoan.mx'}><Icon name='mail' size='huge'/></Link>
		</div> */}
	</div>
}

export default PublicOrder;