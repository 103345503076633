import React, { useEffect, useState } from 'react';
import { Groupper, Input, Button, Message } from 'react-frontier';
import { useTitle } from '../HooksPublic';
import Validator from '@cocoan/components/Validator';
import { SetLoading } from '@cocoan/components/Classes';
import { bindFormChange } from '../UtilPublic';
import APIPublic from '../APIPublic';
import { useNavigate } from 'react-router-dom';

import '../style/search_orders.scss'

interface searchForm{
	order_id: number,
	email: string
}

var SearchOrders = () => {
	var { setTitle } = useTitle();
	var [errorPrompts, setErrorPrompts] = useState<string[]>(null);
	var [form, setForm] = useState<searchForm>({ order_id: null, email: null });
	var navigate = useNavigate();

	useEffect(()=>{
		setTitle('COCOAN - Ordenes', false);
	}, []);

	var search = async(setLoading: SetLoading)=>{
		var { valid, prompts } = Validator({
			...form
		}, {
			order_id: [
				{ rule: 'number', prompt: 'El folio de compra no es válido' }
			],
			email: [
				{ rule: 'email', prompt: 'El correo electrónico no es válido' }
			],
		});
		setErrorPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		APIPublic.searchOrder(form.order_id, form.email).then(res=>{
			if(res.error) return setErrorPrompts([res.message]);
			return navigate(`/order/${res.data.order_hash}`);
		}).catch(err=>{
			return setErrorPrompts(['Hubo un error inesperado buscando la orden. (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var onFormChange = bindFormChange(form, setForm);

	return <div className='co search-orders-screen'>
		<Groupper
			title='Ordenes'
			width={500}
			actions={
				<Button text='Buscar orden' color='black' onClick={search}/>
			}
		>
			<Input label='Número de orden' value={form.order_id} onChange={onFormChange('order_id')}/>
			<Input label='Correo electrónico' value={form.email} onChange={onFormChange('email')}/>
			<Message type='error' list={errorPrompts} />
		</Groupper>
	</div>
}

export default SearchOrders;