import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { NotFound } from '@cocoan/components';
import { CDN_URL } from '../ConfigPublic';
import { useTitle } from '../HooksPublic';
import { AssetType, SitePage, SitePageType } from '../ClassesPublic';
import { Header, Button, Image } from 'react-frontier';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { Modal } from 'semantic-ui-react';
import { bindClick } from '../UtilPublic';
import remarkGfm from 'remark-gfm';
import API from '../APIPublic';

import '../style/site_page.scss'

var Page = ()=>{
	var { '*': page_url } = useParams();
	var [loadError, setLoadError] = useState<string>(null);
	var { setTitle } = useTitle();
	var [page, setPage] = useState<SitePage>(null);
	var [notFound, setNotFound] = useState<boolean>(false);
	var [viewingImage, setViewingImage] = useState<number>(null);
	page_url = page_url.replace(/@/, '');

	useEffect(()=>{
		API.getPage(page_url).then(res=>{
			if(res.error) return setLoadError(res.message);
			if(!res.data) return setNotFound(true);
			setTitle(res.data.page_title)
			setPage(res.data);
		}).catch(err=>{
			return setLoadError('Hubo un error inesperado cargando la pagina (LCL-1)');
		});
	}, []);

	var viewImage = (image: number)=>{
		return ()=>{
			setViewingImage(image)
		}
	}

	if(!page){
		return <Header loading text='Cargando' />
	}
	if(notFound) return <NotFound />
	if(loadError) return <Header iconName='frown open' text='Error' subtext={loadError} />

	var page_pdf = page.page_type===SitePageType.PDF ? page.assets.find(a=>a.asset_type===AssetType.PDF) : null;

	return <div className='co site-page-screen'>
		<div className='masthead' style={{ backgroundImage: `url(${CDN_URL}/pages/banner/${page.page_id}.webp)` }}>
			<div className="title">{page.page_title}</div>
			<div className="subtitle">{page.page_subtitle}</div>
			{page.button_text && (
				<Button text={page.button_text} size="big" color='black' style={{ maxWidth: 200, width: '100%' }} as={Link} to={page.button_url} />
			)}
		</div>
		<div className='content'>
			{page.page_type === SitePageType.TEXT ? (
				<div className='text-content'>
					<ReactMarkdown linkTarget={'_blank'} remarkPlugins={[remarkGfm]} children={page.page_contents} /> 
				</div>
			) : (page.page_type === SitePageType.PDF) ? (
				page_pdf ? (
					<div>
						<a className='pdf-text' href={page_pdf.asset_url} target='_blank'>Haz click aqui si no puedes ver el contenido de la pagina</a>
						<iframe 
							src={`https://drive.google.com/viewerng/viewer?url=${encodeURIComponent(page_pdf.asset_url)}?pid=explorer&efh=false&a=v&chrome=false&embedded=true`} 
							width="100%" 
							height={800}  
							title="PDF Viewer"
						/>
					</div>
				) : null
			) : (
				<div className='assets'>
					{page.assets.filter(a => a.asset_type === AssetType.IMAGE).map(a=>(
						<div className='asset' onClick={viewImage(a.asset_id)}>
							<Image src={a.asset_url} fallback='/placeholder_portrait.webp'/>
							<div className='name'>{a.asset_name}</div>
							<div className='description'>{a.asset_description}</div>
						</div>
					))}
				</div>
			)}
		</div>
		<Modal open={viewingImage!==null} basic onClose={bindClick(setViewingImage)}>
			{viewingImage!==null && <Modal.Content>
				<img 
					src={`${CDN_URL}/pages/assets/${viewingImage}.webp`}
					style={{ width: '100%', maxWidth: 1000, display: 'block', margin: 'auto', borderRadius: 12 }} 
				/>
			</Modal.Content>}
			<Modal.Actions>
				<Button onClick={bindClick(setViewingImage)} text='Cerrar'/>
			</Modal.Actions>
		</Modal>
	</div>
}
export default Page;