import React, { useEffect, useRef, useState } from 'react';
import { Button, Header, Image, Placeholder, Message } from 'react-frontier';
import { Link, useParams } from 'react-router-dom';
import { WebProduct } from '../ClassesPublic';
import { Breadcrumbs } from '../components';
import { addCommas, bindClick } from '../UtilPublic';
import { useCart, useTitle, useWebdata } from '../HooksPublic';
import { usePixel } from '@cocoan/components/Hooks';
import API from '../APIPublic';
import classNames from 'classnames';
import slugify from 'slugify';
import Toast from 'react-hot-toast';
import remarkGfm from 'remark-gfm';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { Modal } from 'semantic-ui-react';

import '../style/product_view.scss'

var ProductView = ()=>{
	var { id } = useParams<{ id: string }>();
	var { setTitle } = useTitle();
	var { loading: loadingCart, addToCart } = useCart();
	var imagesRef = useRef<HTMLDivElement>(null);
	var Pixel = usePixel();
	var [product, setProduct] = useState<WebProduct>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var [quantity, setQuantity] = useState<number>(null);
	var [viewingImage, setViewingImage] = useState<string>(null);
	var webdata = useWebdata();

	useEffect(()=>{
		if(!id) return;
		var product_id = parseInt(id);
		if(Number.isNaN(product_id)) return;

		API.getProduct(product_id, true, true, true, true).then(res=>{
			if(res.error) return setLoadError(res.message);
			setTitle(res.data.product_name);
			setProduct(res.data);
			if(res.data){
				Pixel.viewContent({
					content_ids: [res.data.product_id],
					content_name: res.data.product_name,
					currency: 'MXN',
					value: res.data.price,
					content_type: 'product',
					content_category: res.data.categories && res.data.categories.length>0 ? res.data.categories[0].category_name : ''
				})
			}
		}).catch(err=>{
			setLoadError('Hubo un error inesperado cargando el producto. (LCL-1)');
		});
	}, []);

	var scrollImages = (amt: number)=>{
		return ()=>{
			imagesRef.current.scrollLeft += (window.innerWidth)*amt;
		}
	}

	var addCart = ()=>{
		addToCart(product.product_id, (quantity || 1)).then(()=>{
			Toast.success('¡Producto agregado!', {
				position: 'top-right'
			})
			Pixel.addToCart({
				content_ids: [product.product_id],
				content_name: product.product_name,
				content_type: 'product',
				currency: 'MXN',
				value: product.price*(quantity || 1),
			})
		})
	}

	var viewImage = (image: string)=>{
		return ()=>{
			setViewingImage(image)
		}
	}

	if(loadError){
		return <Header text='Error' subtext={loadError} style={{ marginTop: 20, fontSize: 40 }} subheaderStyle={{ fontSize: 16 }} />
	}

	if(product===null){
		return <div className="co product-screen placeholder">
			<div className="images">
				<div className="item">
					<div className="image"></div>
				</div>
				<div className="item">
					<div className="image"></div>
				</div>
				<div className="item">
					<div className="image"></div>
				</div>
				<div className="item">
					<div className="image"></div>
				</div>
			</div>
			<div className="information">
				<Placeholder height={25} random />
				<div className="name">
					<Placeholder width={240} />
				</div>
			</div>
		</div>
	}

	var granel = !!product.price_gram && product.price_gram>0;
	var main_category = product.categories && product.categories.find(a=>!!a.main);

	return <div className={classNames("co product-screen", {
		placeholder: product===null,
		single: !product.images || product.images.length<=1,
		double: product.images && product.images.length==2,
	})}>
		<div className="images" ref={imagesRef}>
			{product.images.map((a, i)=>(
				<div className="item" key={`prdimg-${i}`}>
					<div className="image" onClick={viewImage(a)}>
						<Image src={a} fallback='/placeholder_portrait.webp' />
					</div>
				</div>
			))}
		</div>
		<div className="navigation left" onClick={scrollImages(-1)}>
			<i className="arrow left icon"></i>
		</div>
		<div className="navigation right" onClick={scrollImages(1)}>
			<i className="arrow right icon"></i>
		</div>
		<div className="information">
			{!!main_category && (
				<Breadcrumbs items={[
					{ text: 'Shop', as: Link, to: '/shop' },
					{ text: main_category.category_name, as: Link, to: `/shop/${main_category.category_id}-${slugify(main_category.category_name).toLowerCase()}`, style: { fontWeight: 'bold' } }
				]} />
			)}
			<div className="name">{product.product_name}</div>
			{!!product.message && (
				<div className="fr label yellow">{product.message}</div>
			)}
			<div className="price">
				{product.price_discounted ? <>
					<div className="final">
						${addCommas(product.price_discounted*(quantity || 1), true, true)}
					</div>
					<div className="discounted">
						${addCommas(product.price*(quantity || 1), true, true)}
					</div>
				</> : (
					`$${addCommas(product.price*(quantity || 1), true, true)}`
				)}
			</div>
			<div className="co presentation">
				<div className="title">{granel ? 'Cantidad' : 'Tamaño'}</div>
				{granel ? (
					<div className="items">
						{new Array(5).fill(0).map((a, i)=>(
							<div key={`amt-${i}`} className={classNames('item', {
								active: quantity===(i+1)
							})} onClick={()=>setQuantity(i+1)}>
								{(i+1)*100} gramos
							</div>
						))}
					</div>
				) : (
					<div className="items">
						<div className="item active">{product.size}</div>
					</div>
				)}
			</div>
			<Button size="big" fluid color="yellow" className="add" text="Agregar a la bolsa" disabled={granel && quantity===null} loading={loadingCart} onClick={addCart} />		
			<div className="section">
				<div className="title">Descripción</div>
				<div className="content">
					<ReactMarkdown linkTarget={'_blank'} remarkPlugins={[remarkGfm]} children={product.description} /> 
				</div>
			</div>
			<div className="section">
				<Message type='info'>
					{webdata && <ReactMarkdown linkTarget={'_blank'} remarkPlugins={[remarkGfm]} children={webdata.variables.shipping_info} /> }
				</Message>
			</div>
		</div>
		<Modal open={viewingImage!==null} size='small' onClose={bindClick(setViewingImage)}>
			<Modal.Header>Imagen</Modal.Header>
			{viewingImage!==null && <Modal.Content>
				<Image src={viewingImage} fallback='/placeholder_portrait.webp' style={{ width: '100%', maxWidth: 500, display: 'block', margin: 'auto' }}/>
			</Modal.Content>}
			<Modal.Actions>
				<Button onClick={bindClick(setViewingImage)} text='Cerrar'/>
			</Modal.Actions>
		</Modal>
	</div>
}

export default ProductView;