import React, { useEffect, useState } from 'react';
import { useTitle, useWebdata } from '../HooksPublic';
import { Button, Header, Image } from 'react-frontier';
import { Carrousel, Product } from '../components';
import { CDN_URL } from '../ConfigPublic';
import { Link } from 'react-router-dom';

import '../style/landing.scss';

var Landing = ()=>{
	var { setTitle } = useTitle();
	var webdata = useWebdata();

	useEffect(()=>{
		setTitle('COCOAN - Chocolatería Sincera', false);
	}, []);
	
	return <div className='co landing'>
		<div className='masthead' style={{ backgroundImage: `url(${CDN_URL}/static/landing/masthead.webp)` }}>
			<div className="text">
				<img src={`${CDN_URL}/static/landing/masthead_text.webp`} />
			</div>
			<Button text="Quiero comprar" size="big" className="yellow" style={{ maxWidth: 200, width: '100%' }} as={Link} to={'/shop'} />
		</div>

		<Carrousel title={webdata?.variables?.landing_category_title || "Tu siguiente antojo 🤤"} titlePlaceholder={!webdata}>
			{!webdata ? (
				(new Array(4).fill(0)).map((a, i)=>(
					<Product placeholder product={null} key={`lnd-prodplh-${i}`} />
				))
			) : (
				webdata.landing_products.map(a=>(
					<Product product={a} key={`lnd-prod-${a.product_id}`} />
				))
			)}
		</Carrousel>

		<div className="co information">
			<img src={`${CDN_URL}/static/landing/about_left.webp`} alt="COCOAN" className="store" />
			<div className="info">
				<div className="title">Nosotros</div>
				<div className="content">
					Nuestra propuesta es sencilla y sincera. <br />
					Queremos ofrecerte la mejor versión de tu chocolate favorito.
				</div>
				<div className="title">Amamos lo que hacemos</div>
				<div className="content">
					No pretendemos complicarte el antojo. <br />
					Solo queremos hacerte feliz con los mejores y más deliciosos chocolates — los que puedas comer todos los días.
				</div>
			</div>
			<img src={`${CDN_URL}/static/landing/about_right.webp`} alt="Love at first bite" className="drawing" />
		</div>
		<Header text='Team COCOAN' className='main' style={{ marginTop: 20, padding: 10, }} subtext='¡Conoce al equipo responsable de cumplir con tus antojos!' />
		<div className="co people" style={{ marginTop: 20, }}>
			{webdata ? (
				webdata.users.map(a=>(
					<div className="co person" key={`person-${a.user_id}`}>
						<Image src={`${CDN_URL}/users/${a.user_id}.webp`} fallback='/placeholder_portrait.webp' />
						<div className="info">
							<div className="name">{a.first_name} {a.last_name}</div>
							<div className="meta">{a.department}</div>
						</div>
					</div>
				))
			) : (
				(new Array(4).fill(0)).map((a,i)=>(
					<div className="co person placeholder" key={`person-plh${i}`}>
						<div className="image"></div>
						<div className="info"></div>
					</div>
				))
			)}
		</div>
		<div style={{ height: 50 }} />
	</div>
}

export default Landing;