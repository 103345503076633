import React, { useEffect, useState } from 'react';
import { NotFound } from '@cocoan/components';
import { Header, Table } from 'react-frontier';
import { useNavigate, useParams } from 'react-router-dom';
import API from '../APIPublic';

var CheckoutResponse = ()=>{
	var { token } = useParams();
	var [loadError, setLoadError] = useState<string>(null);
	var [notFound, setNotFound] = useState<boolean>(false);
	var [data, setData] = useState<{ paid: boolean, message: string, redirect_url: string }>(null);
	var navigate = useNavigate();
	var reg = (/(^(?<o>[0-9a-z]+):(?<p>[0-9]+)$)/gi).exec(token);

	useEffect(()=>{
		var reg = (/(^(?<o>[0-9a-z]+):(?<p>[0-9]+)$)/gi).exec(token);
		if(!reg || !reg.groups.o || !reg.groups.p){
			setNotFound(true);	
			return;
		}
		setLoadError(null);
		setNotFound(false);

		API.getCheckoutResponse(reg.groups.o, parseInt(reg.groups.p)).then(res=>{
			if(res.error) return setLoadError(res.message);
			if(res.data.redirect_url){
				return navigate(res.data.redirect_url);
			}
			setData(res.data);
		}).catch(err=>{
			return setLoadError('Hubo un error inesperado cargando el pago de la orden.');
		});
	}, [token]);

	if(loadError) return <>
		<Header iconName='frown open' text='Error' subtext={loadError} />
		<Table
			details
			striped
			title='Información de pago'
			style={{ maxWidth: 300, margin: 'auto', marginTop: 15 }}
			data={[
				['ID', reg.groups.o],
				['Pago', reg.groups.p]
			]}
		/>
	</>
	if(!reg || notFound) return <NotFound />
	
	return <div>
		<Header text='Cargando orden' loading />
	</div>
}

export default CheckoutResponse;