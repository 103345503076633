import React, { useEffect, useState, createContext, useContext, PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';

const useScript = (url: string) => {
	useEffect(() => {
		const script = document.createElement('script');
		
		script.src = url;
		script.async = true;
		
		document.body.appendChild(script);
		
		return () => {
			document.body.removeChild(script);
		}
	}, [url]);
};

interface PayfrontTokenCard{
	card_name: string, 
	card_number: string, 
	expiration_month: number, 
	expiration_year: number, 
	card_cvv: string, 
	amount: number,
	postal_code: string,
	client_id?: number,
	public_key?: string,
}

interface PayfrontError{
	message_client: string
	message: string,
}

const usePayfront = (client_id?: number, public_key?: string)=>{
	var [initiated, setInitiated] = useState<boolean>(false);
	useEffect(() => {
		var script = document.createElement('script');
		script.src = 'https://api.payfront.mx/js/2.1.1/payfront.min.js';
		document.body.appendChild(script);
		return ()=>{
			document.body.removeChild(script);
		}
	}, [client_id, public_key]);

	var createToken = (data: PayfrontTokenCard) : Promise<{ error: boolean, message?: string, message_internal?: string, data?: { token: string, digits: string, bin: string } }>=>{
		if(!(window as any).Payfront) return Promise.resolve({ error: true, message: 'Hubo un error configuración del procesador de pagos. (LCL-UPF-1)' });

		var bin = data.card_number.toString().substring(0, 6);
		var digits = data.card_number.toString().substring(-4);

		return new Promise((resolve, reject)=>{
			(window as any).Payfront.tokenizeCard({
				client_id,
				public_key,
				name: data.card_name, 
				number: data.card_number, 
				exp: data.expiration_month+''+data.expiration_year, 
				cvv: data.card_cvv,
				amount: data.amount,
				postal_code: data.postal_code
			}, function(err: PayfrontError, token: string){
				return resolve({
					error: !!err,
					message_internal: err ? err.message : undefined,
					message: err ? err.message_client : undefined,
					data: !err ? { token, digits, bin } : undefined
				});
			})
		})
	}

	return {
		createToken,
	}
}

var TitleContext = createContext<{ setTitle: (title: string)=>void }>({ setTitle: (v: string)=>null });
var useTitle = () => useContext(TitleContext);

interface TitleProps extends PropsWithChildren{
	default?: string,
	suffix?: string,
}

var TitleProvider = (props: TitleProps) => {
	var [titleHistory, setTitleHistory] = useState<{ pathname: string, title: string }[]>([]);
	var location = useLocation();

	useEffect(()=>{
		var suffix = props.suffix || 'AREMA Ticket';
		var title = titleHistory.find(a=>a.pathname===location.pathname);
		if(!title){
			document.title = suffix;
		}else{
			document.title = `${title.title} - ${suffix}`;
		}
	}, [location, titleHistory]);

	var changeTitle = (title: string)=>{
		if(!location.key) return;
		var hs = [...titleHistory];
		hs = hs.filter(a=>a.pathname!==location.pathname);
		hs.push({ pathname: location.pathname, title });
		setTitleHistory(hs);
	}

	return (
		<TitleContext.Provider value={{ setTitle: changeTitle }}>
			{props.children}
		</TitleContext.Provider>
	)
}

interface PixelCheckoutData{
	content_ids: any[],
	currency: string,
	eventref?: string,
	num_items: number,
	value: number,
}

interface PixelItemContents{
	id: any,
	quantity: number,
}

type PixelContentType = 'product' | 'product_group';

interface PixelViewContent{
	content_ids: any[],
	content_category?: string,
	content_name: string,
	content_type?: PixelContentType,
	contents?: PixelItemContents[]
	currency: string,
	value: number,
}

interface PixelAddCart{
	content_ids: any[],
	content_name: string,
	content_type: PixelContentType,
	contents?: PixelItemContents[],
	currency: string,
	value: number,
}

interface PixelPurchase{
	content_ids: any[],
	content_name: string,
	content_type: PixelContentType,
	contents: PixelItemContents[],
	currency: string,
	num_items: number,
	value: number,
}

interface PixelPaymentInfo{
	content_category?: string,
	content_ids: any[],
	contents: PixelItemContents[],
	currency: string,
	value: number,
}

var PixelContext = createContext<{
	initiateCheckout: (data: PixelCheckoutData)=>void,
	viewContent: (data: PixelViewContent)=>void,
	addToCart: (data: PixelAddCart)=>void,
	purchase: (data: PixelPurchase, event_id: string)=>void,
	addPaymentInfo: (data: PixelPaymentInfo, event_id: string)=>void,
}>({ 
	initiateCheckout: (data: PixelCheckoutData)=>null,
	viewContent: (data: PixelViewContent)=>null,
	addToCart: (data: PixelAddCart)=>null,
	purchase: (data: PixelPurchase, event_id: string)=>null,
	addPaymentInfo: (data: PixelPaymentInfo, event_id: string)=>null,
});
var usePixel = () => useContext(PixelContext);

interface PixelProps extends PropsWithChildren{
	pixelId: string,
}

var PixelProvider = (props: PixelProps) => {
	useEffect(()=>{
		if(!props.pixelId) return;
		var script = document.createElement('script');
		script.innerHTML = `!function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)}; if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s)}(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js'); fbq('init', '${props.pixelId}'); fbq('track', 'PageView');`

		var noscript = document.createElement('noscript');
		noscript.innerHTML = `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${props.pixelId}&ev=PageView&noscript=1" />`

		document.head.appendChild(script);
		document.head.appendChild(noscript);
		return ()=>{
			document.head.removeChild(script);
			document.head.removeChild(noscript);
		}
	}, []);

	function initiateCheckout(data: PixelCheckoutData){
		if(!(window as any).fbq) return;
		(window as any).fbq('track', 'InitiateCheckout', {
			currency: data.currency, 
			value: data.value,
			num_items: data.num_items,
			content_ids: data.content_ids,
			eventref: data.eventref || ''
		});
	}

	function viewContent(data: PixelViewContent){
		if(!(window as any).fbq) return;
		(window as any).fbq('track', 'ViewContent', data);
	}

	function addToCart(data: PixelAddCart){
		if(!(window as any).fbq) return;
		(window as any).fbq('track', 'AddToCart', data);
	}

	function purchase(data: PixelPurchase, event_id: string){
		if(!(window as any).fbq) return;
		(window as any).fbq('track', 'Purchase', data, { eventID: event_id });
	}

	function addPaymentInfo(data: PixelPaymentInfo, event_id: string){
		if(!(window as any).fbq) return;
		(window as any).fbq('track', 'AddPaymentInfo', data, { eventID: event_id });
	}

	return (
		<PixelContext.Provider value={{
			initiateCheckout,
			viewContent,
			addToCart,
			purchase,
			addPaymentInfo,
		}}>
			{props.children}
		</PixelContext.Provider>
	)
}


export {
	usePayfront,
	useScript,

	useTitle,
	TitleProvider,

	usePixel,
	PixelProvider
};