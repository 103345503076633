import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter, useRouteError, Outlet, useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { TitleProvider, CartProvider, WebdataProvider } from './HooksPublic'
import { Header } from 'react-frontier'
import { Navbar, Footer } from './components';
import { SPECIAL_CATEGORIES } from './UtilPublic';
import { PixelProvider } from '@cocoan/components/Hooks'
import { META_PIXEL } from './ConfigPublic';
import classNames from 'classnames';


import {
	Checkout,
	Landing,
	PaymentInstructions,
	ProductView,
	PublicOrder,
	Shop,
	InvoiceCreate,
	VisitUs,
	HelpView,
	TermsView,
	SearchOrders,
	CheckoutLink,
	CheckoutResponse,
	ReceiptView,
	Page
} from './screens';

import 'fomantic-ui-css/semantic.css';
import 'frontier-css/css/frontier.css';
import './style/site.scss'


var ErrorElement = ()=>{
	const error = (useRouteError() as any);
	return <Header
		text={error.status}
		subtext={error.status===404 ? 'Página no encontrada' : `Hubo un error inesperado. (PBL-LCL-${error.status})`}
		style={{ fontSize: 100 }}
		subheaderStyle={{ fontSize: 20 }}
		containerStyle={{ marginTop: 15 }}
	/>
}

var SiteContainer = (props: { outlet: JSX.Element })=>{
	var location = useLocation();
	var fullscreen = ['/checkout'];
	var no_navbar = ['/r/'];
	var IS_FULLSCREEN = fullscreen.findIndex(a=>location.pathname.startsWith(a))!=-1;
	var HAS_NAVBAR = no_navbar.findIndex(a=>location.pathname.startsWith(a))==-1;
	
	return <TitleProvider suffix='COCOAN'>
		<PixelProvider pixelId={META_PIXEL}>
			<WebdataProvider>
				<CartProvider>
					{HAS_NAVBAR && <Navbar />}
					<div className={classNames('co body-container', {
						fullscreen: IS_FULLSCREEN,
					})}>
						{props.outlet}
					</div>
					{!IS_FULLSCREEN && HAS_NAVBAR && <Footer />}
				</CartProvider>
			</WebdataProvider>
		</PixelProvider>
	</TitleProvider>
}

const Router = createBrowserRouter([{
	path: '/',
	errorElement: <SiteContainer outlet={<ErrorElement />} />,
	element: <SiteContainer outlet={<Outlet />} />,
	children: [
		{ path: '/', element: <Landing /> },
		{ path: '/shop', element: <Shop /> },
		{ path: '/shop/:category', element: <Shop /> },
		{ path: '/facturar', element: <InvoiceCreate /> },
		{ path: '/search', element: <Shop search /> },
		{ path: '/product/:id', element: <ProductView /> },
		{ path: '/checkout/link/:token', element: <CheckoutLink /> },
		{ path: '/checkout/:token/openpay', element: <CheckoutResponse /> },
		{ path: '/checkout*', element: <Checkout /> },
		{ path: '/order/:hash', element: <PublicOrder /> },
		{ path: '/orders/:hash', element: <PublicOrder /> },
		{ path: '/order/:hash/payment', element: <PaymentInstructions /> },
		{ path: '/ayuda', element: <HelpView /> },
		{ path: '/terminos', element: <TermsView /> },
		{ path: '/visitanos', element: <VisitUs /> },
		{ path: '/ordenes', element: <SearchOrders /> },
		{ path: '/r/:hash', element: <ReceiptView /> },

		// === SPECIAL CATEGORIES ===
		...SPECIAL_CATEGORIES.map(a=>(
			{ path: a.route, element: (
				<Shop id={a.category} />
			)}
		)),

		// === PDFS ===
		{ path: '/patrones', element: <Shop /> },
		{ path: '/*', element: <Page /> },
	],
}]);

var Root = ()=>{
	return (
		<>
			<Toaster position="bottom-right" toastOptions={{
				duration: 5000,
				error: {
					iconTheme: {
						primary: 'white',
						secondary: '#ff4b4b'
					},
					style: {
						color: 'white',
						background: '#ff4b4b',
					}
				},
				success: {
					iconTheme: {
						primary: 'white',
						secondary: 'rgb(89, 179, 67)'
					},
					style: {
						color: 'white',
						background: 'rgb(89, 179, 67)'
					}
				}
			}} />
			<RouterProvider router={Router}/>
		</>
	)
}

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(<Root />);