export interface WebCategory{
	category_id: number,
	category_name: string,
	priority: number,
}

export interface WebProduct{
	product_id: number,
	product_name: string,
	price: number,
	price_pdv?: number,
	price_discounted?: number,
	price_gram?: number,
	price_fix?: number,
	tax_ieps?: number,
	size: string,
	message: string,
	description: string,
	amount: number,
	images: string[],
	categories: {
		category_id: number,
		category_name: string,
		main: boolean,
	}[],
	metadata: {
		metadata_id: number,
		metadata_name: string,
		metadata_value_id: number,
		metadata_value: string,
	}[]
}

export interface CheckoutOrder{
	order_id: number,
	order_hash: string,
	total: number,
	owner_email: string,
	owner_name: string,
	owner_last_name: string,
	owner_phone: string,
	shipping_type?: number,
	shipping_method: number,
	shipping_method_name: string,
	shipping_method_type: number,
	shipping_method_local: boolean,
	shipping_cost: number,
	shipping_date: number,
	shipping_location?: number,
	gift: boolean,
	gift_name: string,
	gift_last_name: string,
	gift_message: string,
	gift_phone: string,
	paid: boolean,
	executed: boolean,
	seller_id: number,
	location?: {
		street: string,
		exterior_number: string,
		interior_number: string,
		neighborhood: string,
		city: string,
		state: string,
		zipcode: string,
		comments?: string,
	},
	items: {
		product_id: number,
		product_name: string,
		cost: number,
		cost_single: number,
		quantity: number,
		size: string,
	}[]
}

export interface Location{
	location_id?: number,
	name?: string,
	phone?: string,
	street: string,
	exterior_number: string,
	interior_number?: string,
	neighborhood: string,
	city: string,
	state: string,
	zipcode: string,
	country?: string,
	lattitude?: number,
	longitude?: number,
	place_id?: string,
	comments?: string,
}

export enum ProcessorProvider{
	PAYFRONT = 1,
	OPENPAY = 2,
}

export type SetLoading = (l: boolean)=>void;