import React, { useEffect } from 'react';
import { useTitle } from '../HooksPublic';
import { Header } from 'react-frontier';
import { Icon } from 'semantic-ui-react'
import { CDN_URL } from '../ConfigPublic';

import '../style/visit_us.scss'

var VisitUs = ()=>{
	var { setTitle } = useTitle();

	useEffect(()=>{
		setTitle('COCOAN - Vísitanos', false);
	}, []);

	return <div className='co visit-us-screen'>
		<Header text='Vísitanos' className='main-header'/>
		<div className='main-content'>
			<div className='image-container'>
				<div className="row">
					<img src={`${CDN_URL}/static/visit/grid1.webp`} />
					<img src={`${CDN_URL}/static/visit/grid2.webp`} />
				</div>
				<div className="row">
					<img src={`${CDN_URL}/static/visit/grid3.webp`} />
					<img src={`${CDN_URL}/static/visit/grid4.webp`} />
				</div>
			</div>
			<div className='info-content'>
				<Header text='Encuéntranos' style={{ marginTop: 0 }} />
				<iframe
					width={450}
					height={250}
					referrerPolicy="no-referrer-when-downgrade"
					title='COCOAN'
					src={`https://www.google.com/maps/embed/v1/place?q=place_id:ChIJecQfM-S9YoYRedl-Tdt7jbs&key=${process.env.REACT_APP_PLACES_KEY}`}
					style={{ border: '1px solid #ececec', borderRadius: 12 }}
				/>
				<div className='address'>
					<div>
						<Icon name='map marker alternate'/>
					</div>
					Rio Manzanares 425-C, Colonia del Valle, 66220 <br />
					San Pedro Garza García, Nuevo León, México
				</div>

				<Header text='Contáctanos' className='sub-header'/>
				<p className='contact'>
					<Icon name='mail'/>hola@cocoan.mx <br/>
					<Icon name='phone'/>81 2316 0056 <br/>
					<Icon name='facebook'/><a href="https://www.facebook.com/cocoanchocolateria">cocoanchocolateria</a><br/>
					<Icon name='instagram'/><a href="https://www.instagram.com/cocoanchocolateria/">cocoanchocolateria</a> <br />
					<Icon name='whatsapp'/><a href="https://wa.me/528129448353">81 2944 8353</a>
				</p>

				<Header text='Horario' className='sub-header'/>
				<p className='schedule'>
					Lunes - Viernes : 11am - 7pm<br/>
					Sábado : 11am - 6pm<br/>
					Domingo: <span className='closed'>Cerrado</span><br/>
				</p>
			</div>
		</div>
	</div>
}

export default VisitUs;