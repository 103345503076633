import React, { useEffect, useState } from 'react';
import API from '../APIPublic';
import { useParams } from 'react-router-dom';
import { Button, Groupper, Header, Table } from 'react-frontier';
import { NotFound } from '@cocoan/components';

import "../style/payment_instructions.scss";
import { useTitle } from '../HooksPublic';
import { addCommas } from '../UtilPublic';

interface InstructionData{
	order_id: number,
	order_hash: string,
	paid: boolean,
	payment_method: number,
	payment_method_name: string,
	reference: string,
	reference_barcode: string,
	payment_deadline: number,
	amount: number,
	bank_account: {
		bank: string,
		name: string,
		clabe: string,
		agreement: string,
		description: string,
	},
}

var PaymentInstructions = ()=>{
	var { hash } = useParams();
	var [data, setData] = useState<InstructionData>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var { setTitle } = useTitle();

	useEffect(()=>{
		if(!hash || hash.length<3) return;
		API.getPaymentInstructions(hash).then(res=>{
			if(res.error) return setLoadError(res.message);
			setData(res.data);
			setTitle(`Pago #CCN${res.data.order_id}`);
		}).catch(err=>{
			return setLoadError('Hubo un erro inesperado cargando la información (LCL-1)');
		})
	}, []);

	if(loadError) return <NotFound error subtext={loadError} />
	if(!data) return <Header loading text='Cargando instrucciones de pago' />
	
	return <div className="co payment-instructions">
		<Header text={`Orden #CCN${data.order_id}`} subtext='Instrucciones de pago' />
		<Button text='Imprimir' iconName='print' className='print' onClick={()=>window.print()} />
		
		<div className="amount">
			<div className="label">Total a pagar</div>
			<div className="value">${addCommas(data.amount)}</div>
			<div className="label meta">Favor de pagar la <b>CANTIDAD EXACTA</b></div>
		</div>

		{data.payment_method==11 ? (
			<Groupper width={500} style={{ marginTop: 15 }} title='Desde BBVA' className='spei'>
				<ol>
					<li>Dentro del menú de "Pagar" seleccione la opción "De Servicios" e ingrese el siguiente "Número de convenio CIE"</li>
				</ol>
				<table>
					<tr>
						<td>Número de convenio CIE</td>
						<td>{data.bank_account.agreement}</td>
					</tr>
				</table>
				<ol start={2}>
					<li>Ingrese los datos de registro para concluir con la operación.</li>
				</ol>
				<table>
					<tr>
						<td>Referencia</td>
						<td>{data.bank_account.name}</td>
					</tr>
					<tr>
						<td>Importe</td>
						<td>${addCommas(data.amount)}</td>
					</tr>
					<tr>
						<td colSpan={2} style={{ fontSize: 12, color: 'brown' }}>Favor de pagar la cantidad EXACTA</td>
					</tr>
					<tr>
						<td>Concepto</td>
						<td>{data.bank_account.description}</td>
					</tr>
				</table>
				<Groupper.Divider text='Desde cualquier otro banco' />
				<ol>
					<li>Ingresa a la sección de transferencias y pagos o pagos a otrosbancos y proporciona los datos de la transferencia:</li>
				</ol>
				<table>
					<tr>
						<td><b>Beneficiario</b></td>
						<td>Cocoan Chocolateria Sincera</td>
					</tr>
					<tr>
						<td><b>Banco destino</b></td>
						<td>{data.bank_account.bank}</td>
					</tr>
					<tr>
						<td><b>CLABE</b></td>
						<td>{data.bank_account.clabe}</td>
					</tr>
					<tr>
						<td><b>Concepto de pago</b></td>
						<td>{data.bank_account.name}</td>
					</tr>
					<tr>
						<td><b>Referencia</b></td>
						<td>{data.bank_account.agreement}</td>
					</tr>
					<tr>
						<td><b>Importe</b></td>
						<td>${addCommas(data.amount)}</td>
					</tr>
					<tr>
						<td colSpan={2} style={{ fontSize: 12, color: 'brown' }}>Favor de pagar la cantidad EXACTA</td>
					</tr>
				</table>
			</Groupper>
		) : data.payment_method==10 ? <div className="oxxo">
			<img src={data.reference_barcode} className='barcode' />
			<div className="code">
				{data.reference}
			</div>
			<ul>
				<li>Acude a tu tienda OXXO más cercana.</li>
				<li>Indica en caja que quieres realizar un pago de OXXOPay.</li>
				<li>Dicta o enséñale el código superior al cajero.</li>
				<li>Realiza el pago con efectivo.</li>
				<li>Cuando nos llegue la confirmación de pago, te mandaremos un correo confirmando tu compra.</li>
			</ul>
		</div> : null}
	</div>
}

export default PaymentInstructions;