import React, { useEffect, useState, createContext, useContext, PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import { CartItem, SerializedCartItem, WebData } from './ClassesPublic';
import Toast from 'react-hot-toast';
import API from './APIPublic';

var TitleContext = createContext<{ setTitle: (title: string, suffix?: boolean)=>void }>({ setTitle: (v: string)=>null });
var useTitle = () => useContext(TitleContext);

interface TitleProps extends PropsWithChildren{
	default?: string,
	suffix?: string,
}

var TitleProvider = (props: TitleProps) => {
	var [titleHistory, setTitleHistory] = useState<{ pathname: string, title: string, suffix: boolean }[]>([]);
	var location = useLocation();

	useEffect(()=>{
		var suffix = props.suffix || '';
		var title = titleHistory.find(a=>a.pathname===location.pathname);
		if(!title){
			document.title = suffix;
		}else{
			document.title = title.suffix ? `${title.title} - ${suffix}` : title.title;
		}
	}, [location, titleHistory]);

	var changeTitle = (title: string, suffix: boolean=true)=>{
		if(!location.key) return;
		var hs = [...titleHistory];
		hs = hs.filter(a=>a.pathname!==location.pathname);
		hs.push({ pathname: location.pathname, title, suffix: suffix!==false });
		setTitleHistory(hs);
	}

	return (
		<TitleContext.Provider value={{ setTitle: changeTitle }}>
			{props.children}
		</TitleContext.Provider>
	)
}

interface CartContextProps{
	cart: CartItem[],
	serializedCart: SerializedCartItem[],
	items: number,
	loading: boolean,
	showing: boolean,
	total: number,
	showCart: ()=>void,
	hideCart: ()=>void,
	addToCart: (product_id: number, quantity: number, show_cart?: boolean)=>Promise<any>,
	removeFromCart: (product_id: number)=>void,
	updateCart: (product_id: number, quantity: number)=>Promise<any>,
	clearCart: ()=>void,
}

var CartContext = createContext<CartContextProps>({
	cart: [],
	serializedCart: [],
	items: 0,
	loading: false,
	showing: false,
	total: 0,
	showCart: ()=>null,
	hideCart: ()=>null,
	addToCart: ()=>null,
	removeFromCart: ()=>null,
	updateCart: ()=>null,
	clearCart: ()=>null,
});

var useCart = () => useContext(CartContext);

interface CartProps extends PropsWithChildren{}

var CartProvider = (props: CartProps)=>{
	var [showing, setShowing] = useState<boolean>(false);
	var [cart, setCart] = useState<CartItem[]>([]);
	var [loading, setLoading] = useState<boolean>(true);

	useEffect(()=>{
		fetchCart();
	}, []);

	var getSerializedCart = () : SerializedCartItem[]=>{
		var scart = localStorage.getItem('cocoan-cart');
		if(!scart) return [];
		return JSON.parse(scart);
	}

	var saveSerializedCart = (cart: CartItem[])=>{
		var serialized : SerializedCartItem[] = [];
		for(var i of cart){
			serialized.push({
				product_id: i.product_id,
				quantity: i.quantity,
				granel: !!i.price_gram && i.price_gram>0,
			});
		}
		if(serialized.length>0){
			localStorage.setItem('cocoan-cart', JSON.stringify(serialized))
		}else{
			localStorage.removeItem('cocoan-cart');
		}
	}

	var fetchCart = ()=>{
		var scart = getSerializedCart();
		if(!scart || scart.length==0){
			setLoading(false);
			return;
		}
		API.getCart(scart).then(res=>{
			if(res.error){
				setCart([]);
				saveSerializedCart([]);
				return;
			}
			saveSerializedCart(res.data);
			setCart(res.data);
		}).catch(err=>{
			saveSerializedCart([]);
			setCart([]);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var addToCart = async (product_id: number, quantity: number, show_cart: boolean=true)=>{
		if(!product_id || !quantity || quantity<=0) return;
		
		var cix = cart.findIndex(a=>a.product_id==product_id);
		if(cix>-1){
			var ct = [...cart];
			ct[cix].quantity += quantity;
			setCart(ct);
			saveSerializedCart(ct);
			if(show_cart) showCart();
			return;
		}
		
		setLoading(true);
		try{
			var res = await API.getCartItem(product_id, quantity)
		}catch(e){
			Toast.error('Hubo un error inesperado agregando el producto al carrito. (LCL-1)');
			return;
		}finally{
			setLoading(false);
		}

		if(res.error){
			Toast.error(res.message);
			return;
		}
		
		var nct = [...cart];
		nct.push(res.data);
		setCart(nct);
		saveSerializedCart(nct);
		if(show_cart) showCart();
	}

	var removeFromCart = (product_id: number)=>{

	}

	var clearCart = ()=>{
		setCart([]);
		saveSerializedCart([]);
	}
	
	var updateCart = async (product_id: number, quantity: number)=>{
		var cix = cart.findIndex(a=>a.product_id==product_id);
		if(cix>-1){
			var ct = [...cart];
			if(quantity<=0){
				ct.splice(cix, 1);
			}else{
				ct[cix].quantity = quantity;
			}
			setCart(ct);
			saveSerializedCart(ct);
			return;
		}
	}

	var getCartItems = ()=>{
		if(cart.length==0) return 0;
		var amt = 0;
		for(var i of cart){
			amt += Math.floor(i.quantity || 1);
		}
		return amt;
	}

	var getTotal = ()=>{
		if(!cart) return 0;
		var amt = 0;
		for(var i of cart){
			amt += (i.price_discounted || i.price) * i.quantity;
		}
		return amt;
	}

	var showCart = ()=>{
		setShowing(true);
	}

	var hideCart = ()=>{
		setShowing(false);
	}

	return (
		<CartContext.Provider value={{
			cart, loading, showing,
			serializedCart: getSerializedCart(),
			addToCart,
			removeFromCart,
			updateCart,
			showCart,
			hideCart,
			clearCart,
			total: getTotal(),
			items: getCartItems(),
		}}>
			{props.children}
		</CartContext.Provider>
	)
}

var WebdataContext = createContext<WebData>({
	landing_products: null,
	users: null,
	variables: null
});

var useWebdata = () => useContext(WebdataContext);
var WebdataProvider = (props: CartProps)=>{
	var [webdata, setWebdata] = useState<WebData>(null);

	useEffect(()=>{
		if(webdata) return;
		API.getVariables().then(res=>{
			if(res.error) return;
			setWebdata(res.data);
		});
	}, [webdata]);

	return (
		<WebdataContext.Provider value={webdata}>
			{props.children}
		</WebdataContext.Provider>
	)
}

export{
	TitleProvider,
	useTitle,

	CartProvider,
	useCart,

	WebdataProvider,
	useWebdata,
}