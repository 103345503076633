import React, { useEffect, useState } from 'react';
import { Groupper, Header, Table, Button } from 'react-frontier';
import { Link, useParams } from 'react-router-dom';
import { useTitle } from '../HooksPublic';
import { NotFound } from '@cocoan/components';
import API from '../APIPublic';
import moment from 'moment';
import { CDN_URL } from '../ConfigPublic';
import { addCommas } from '../UtilPublic';
import { Icon } from 'semantic-ui-react'

import "../style/order_receipt.scss";

interface OrderReceipt{
	order_id: number,
	order_hash: string,
	date_created: number,
	total: number,
	items: {
		item_id: number,
		product_id: number,
		product_name: string,
		size: string,
		quantity: number,
		cost_single: number,
		cost: number
	}[],
	payments: {
		amount: number,
		change_amount: number
	}[],
}

var ReceiptView = ()=>{
	var { hash } = useParams();
	var { setTitle } = useTitle();
	var [order, setOrder] = useState<OrderReceipt>(null);
	var [loadError, setLoadError] = useState<string>(null);

	useEffect(()=>{
		if(!hash || hash.length<3) return;
		API.getOrderData(hash).then(res=>{
			if(res.error) return setLoadError(res.message);
			setTitle(`Recibo Orden #CCN${res.data.order_id}`)
			setOrder(res.data);

		}).catch(err=>{
			return setLoadError('Hubo un error inesperado cargando la orden (LCL-1)');
		});
	}, []);
	
	if(!hash || hash.length<3) return <NotFound />
	if(!order) return <Header loading text='Cargando orden...' />
	if(loadError) return <NotFound subtext={loadError} />

	return <div className='co receipt'>
		<Groupper style={{ maxWidth: 500 , marginTop: -50}}>
			<div className='header'>
				<img src={`${CDN_URL}/static/logo/Logo_Black_Tiny.png`} alt="COCOAN" className='logo'/>
					<div>Rio Manzanares 425-C</div>
					<div>Colonia del Valle, 66220</div>
					<div>San Pedro Garza Garcia</div>
					<div>Nuevo Leon, Mexico</div>
					<div>{moment.unix(order.date_created).format('DD/MMM/YY HH:mm')}</div>
			</div>
			<Table
				headers={['Producto', 'Cantidad', 'Precio']}
				collapsingIndexes={[1 , 2]}
				centeredIndexes={[1]}
				className='products-table'
			>
			{order.items.map(i=>{
				return (
					<Table.Row
						className='row'
						centeredIndexes={[1]}
						data={[
							i.product_name,
							i.quantity,
							'$'+addCommas(i.quantity*i.cost_single)
						]}
					/>
				)
			})}
			<Table.Row
				className='row'
				centeredIndexes={[1]}
				data={[
					null,
					<div className='label'>Cambio</div>,
					'$'+addCommas((order.payments.reduce((p, payment) => p + payment.change_amount, 0)))
				]}
			/>
			<Table.Row
				className='row'
				centeredIndexes={[1]}
				data={[
					null,
					<div className='label'>Total</div>,
					<div className='label'>{'$'+addCommas(order.total)}</div>
				]}
			/>
			</Table>
			<div className="footer">
				<div><b>SHOP ONLINE</b></div>
				<a href="https://cocoan.mx" className='cocoan-link'>WWW.COCOAN.MX</a>
				<div className='socials'>	
					<Icon name='phone'/>(81) 2316 0056 <br/>
					<Icon name='mail'/>hola@cocoan.mx <br/>
					<Icon name='facebook'/><a href="https://www.facebook.com/cocoanchocolateria">cocoanchocolateria</a><br/>
					<Icon name='instagram'/><a href="https://www.instagram.com/cocoanchocolateria/">cocoanchocolateria</a> <br />
					<Icon name='whatsapp'/><a href="https://wa.me/528129448353">81 2944 8353</a>
				</div>
				<div><b>FACTURA ONLINE</b></div>
				<Button text='Facturar' color='black' style={{ marginTop: 10}} size='big' as={Link} to={`/facturar?o=${order.order_hash}`}/>
			</div>
		</Groupper>
	</div>
}

export default ReceiptView;