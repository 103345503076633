import classNames from 'classnames';
import React, { ElementType, useEffect, useState } from 'react';

interface BreadcrumbsItem{
	text: string,
	style?: React.CSSProperties,
	as?: ElementType,
	className?: string,
	onClick?: ()=>void,
	[x: string]: any,
}

interface BreadcrumbsProps{
	items: (BreadcrumbsItem | string)[],
	active?: number,
	activeStyle?: React.CSSProperties
	afterStyle?: React.CSSProperties,
	beforeStyle?: React.CSSProperties,
}

var Breadcrumbs = (props: BreadcrumbsProps)=>{
	useEffect(()=>{
		
	}, []);
	
	return <div className="fr breadcrumbs">
		{props.items.map((a, i, arr)=>{
			var item : BreadcrumbsItem = null;
			if(typeof a==='string'){
				item = { text: a };
			}else{
				item = a;
			}
			
			var { text, href, style, as, onClick, ...restProps } = item;
			var Element = item.as || 'div';
			
			var active = typeof props.active!=='undefined' ? i==props.active : i==(arr.length-1);
			var before = typeof props.active!=='undefined' ? i<props.active : i<(arr.length-1);
			var after = typeof props.active!=='undefined' && i>props.active;
			
			return <React.Fragment key={`brc-${item.text}-${i}`} >
				<Element className={classNames("item", {
					active: active
				})} style={{ 
					...item.style, 
					...(active ? props.activeStyle : {}),
					...(before ? props.beforeStyle : {}),
					...(after ? props.afterStyle : {}),
				}} onClick={item.onClick} {...restProps}>
					{item.text}
				</Element>
				{i<(arr.length-1) && (
					<i className="chevron right icon"></i>
				)}
			</React.Fragment>
		})}
	</div>
}

export default Breadcrumbs;