import React, { useEffect } from 'react';
import { Header } from 'react-frontier';
import { useTitle } from '../HooksPublic';

import '../style/help.scss'

var HelpView = ()=>{
	var { setTitle } = useTitle();

	useEffect(()=>{
		setTitle('Ayuda');
	}, []);

	return <div className='co help-screen'>
		<div className='main-container'>
			<Header text='¿Necesitas ayuda?' className='secondary-header'/>
			<p>Nuestro equipo de Club Cocoan está aquí para ayudarte. Escríbenos con cualquier pregunta que tengas de nuestros productos, pedidos, página web o del chocolate en general. ¡También puedes enviarnos tus sugerencias!</p>
			<div className='mail-header'>
				<a href="mailto:hola@cocoan.mx" style={{ fontStyle: 'normal' }}>hola@cocoan.mx</a>
			</div>
			<Header text='Sobre las compras.' className='main-header'/>
			<Header text='¿Cómo hago una compra?' className='secondary-header'/>
			<p>¡Es muy fácil! <br />Tienes dos opciones para realizar tu compra web. Puedes hacerla por medio de nuestra tienda en línea o puedes llamarnos al <a href="tel:+8123160056" style={{ whiteSpace: 'nowrap' }}>81 2316 0056</a> si necesitas asesoría de nuestros productos o si deseas hacerle un regalo a alguien y no estás seguro de qué enviarle. Con gusto te apoyaremos por teléfono y te enviaremos a tu correo un link de pago con el desglose de tu pedido.</p>
			<p>Para realizar tu compra web deberás:</p>
			<ol>
				<li>Agregar los productos que deseas adquirir a tu carrito de compra.</li>
				<li>Cuando tu orden esté completa, da click en el carrito que se encuentra en la parte superior de la página y revisa tu pedido.</li>
				<li>Da click en “Finalizar compra” para ingresar tus datos de envío en la página a continuación.</li>
				<li>Si seleccionas la opción de “Esta compra es un regalo”, deberás ingresar el nombre de la persona que recibirá el pedido en la primera opción de “Nombre/Quien recibe”. Te pediremos que ingreses tu nombre completo y un mensaje de regalo.</li>
				<li>Da click en “Continuar” y te presentaremos las opciones de pago que manejamos. Podrás elegir la que sea más conveniente para ti.</li>
				<li>Si elegiste la opción de pago en efectivo en tiendas de conveniencia o transferencia bancaria, el envío no será procesado hasta que hayas realizado el pago y nuestro sistema lo valide.</li>
				<li>Al finalizar el proceso de pago recibirás un correo con tu orden de confirmación.</li>
			</ol>
			<Header className="secondary-header" text="¿Qué formas de pago manejan?" />
			<p>Todas las compras realizadas en nuestra tienda en línea pasan por el sistema de cobro Openpay. Puedes realizar el pago con <b>tarjeta de crédito</b> (Visa, Mastercard o American Express), <b>tarjeta de débito</b>, pagos en <b>efectivo OXXO</b> o mediante transferencia electrónica (SPEI).</p>
			<Header className="secondary-header" text="¿Qué hago si mi tarjeta fue declinada?" />
			<p>Nuestro sistema de cobro Openpay tiene un sistema de seguridad llamado <b>3D secure.</b> 3D Secure es la nueva forma de pago desarrollada por <b>Visa y Mastercard</b> que posibilita la realización de <b>compras seguras en Internet</b> y autentifica al comprador como legítimo titular de la tarjeta que está utilizando.</p>
			<p>En algunas ocasiones, la comunicación con el banco se pierde y nos arroja un error al pasar por este sistema de seguridad. <b>Te recomendamos intentar con otra tarjeta o bien, utilizar el método de transferencia electrónica (SPEI) o descargar el formato de Oxxo Pay.</b></p>
			<Header className="secondary-header" text="¿Cómo funciona el pago por transferencia bancaria (SPEI)?" />
			<p>¡Es súper sencillo! Al escoger este método de pago, te enviaremos por correo un formato con todas las instrucciones que necesitas para realizar la transferencia. Es muy importante que <b>primero leas las instrucciones</b>, ya que el sistema te asigna un <b>número de referencia único</b> para poder ubicar tu pago y liberar tu pedido.</p>
			<Header className="secondary-header" text="¿Cómo solicito una factura?" />
			<p>Si requieres factura por favor llena el formulario de <a href="/facturar">facturación</a> que incluye tu número de orden, correo electrónico y datos completos de facturación. Recuerda solicitar tu factura dentro del mes en el que realizaste tu compra.</p>
			<Header className="secondary-header" text="¿Cómo puedo ser mayorista/distribuidor?" />
			<p>Si tienes un negocio y te gustaría complementarlo con nuestros chocolates puedes contactarnos al correo de <a href="mailto:hola@cocoan.mx">hola@cocoan.mx</a> y con gusto te asesoraremos.</p>
			<Header className="main-header" text="Sobre los envíos." />
			<Header className="secondary-header" text="¿Hacen envíos a domicilio si vivo en Monterrey?" />
			<p><b>¡Sí!</b> Todas las compras realizadas antes de las 2 pm se procesan el mismo día. El horario de entrega es de 2 pm a 6 pm de lunes a sábado. Te enviaremos un correo en cuanto tu pedido salga a su destino. Si deseas programarlo para una fecha específica, por favor envíanos un correo a o llámanos al  <a href="tel:+8123160056" style={{ whiteSpace: 'nowrap' }}>81 2316 0056</a> y con gusto lo agendamos. El costo del envio en Monterrey, Nuevo León es de $85 (válido en compras realizadas en la página web).</p>
			<Header className="secondary-header" text="¿Hacen envíos nacionales si no vivo en Monterrey?" />
			<p>¡Sí! Si tu dirección de entrega se encuentra fuera de Monterrey, Nuevo León realizaremos el envío por medio de Estafeta con un costo de $155 (ciento cincuenta y cinco pesos mexicanos) para envío de 3 a 6 días hábiles. Cocoan Chocolatería respalda todos los envíos y nos aseguramos de que lleguen a su destino, así como también ofrecemos apoyo a la paquetería con la que realizamos el envío.</p>
			<p>Para asegurar la frescura de nuestros productos, solamente realizamos envíos nacionales por paquetería de lunes a miércoles con el fin de evitar que los paquetes se queden durante el fin de semana en bodega y sufran cambios de temperatura que puedan afectar sus características.</p>
			<Header className="secondary-header" text="¿Cómo puedo obtener envío gratis?" />
			<p>¡Todas las compras arriba de $750 obtendrán envío gratuito!</p>
			<Header className="secondary-header" text="¿Qué hago si me equivoqué en la dirección de envío o deseo cambiarla?" />
			<p>Para cualquier cambio en la información de envío que ingresaste, por favor comunícate con nosotros a <a href="mailto:hola@cocoan.mx">hola@cocoan.mx</a> lo antes posible. No será posible modificar la dirección una vez que tu pedido sea enviado y recibas el correo de confirmación de envío.</p>
			<Header className="secondary-header" text="¿Cómo puedo revisar el estatus de mi orden?" />
			<p>Podrás revisar el estatus en la sección de “Órdenes” dentro del menú de opciones en <a href="http://www.cocoan.mx">www.cocoan.mx</a>. Para revisar a tu pedido deberás contar con el número de orden y correo que ingresaste al momento de realizar tu compra.</p>
			<Header className="secondary-header" text="¿Cómo puedo modificar mi orden?" />
			<p>Por favor contáctanos lo más pronto posible si deseas agregar o modificar tu pedido. Tratamos de procesar los pedidos rápidamente para que puedas disfrutar de tus chocolates, por lo que no podemos hacer cambios a tu orden si ésta ya ha sido enviada.</p>
			<Header className="secondary-header" text="¿Qué hago si no recibí los productos correctos?" />
			<p>Si el producto que llegó a tu casa no coincide con tu orden de compra, por favor contáctanos lo antes posible y nos aseguraremos de enviar tu orden correctamente. Puedes hacerlo por correo a <a href="mailto:hola@cocoan.mx">hola@cocoan.mx</a>.</p>
			<Header className="main-header" text="Sobre el chocolate de Cocoan." />
			<Header className="secondary-header" text="¡Quiero probar todo! ¿Dónde los encuentro?" />
			<p>¡Visítanos en nuestra boutique de chocolatería sincera en Monterrey! Estamos ubicados en Río Manzanares 425, Col. Del Valle en San Pedro Garza García, NL. Aquí podrás probar y encontrar toda nuestra línea de productos, desde chocolates a granel, tabletas, fresas con chocolate, trufas, chocolates rellenos y gelato.</p>
			<p>¡Esperamos poder llevar nuestro taller de chocolatería a más ciudades pronto!</p>
			<Header className="secondary-header" text="¿De dónde proviene el chocolate de Cocoan?" />
			<p>Actualmente trabajamos con Casa Luker, una marca de chocolate colombiano. La calidad del cacao Luker es excepcional, los perfiles de aromas y sabores son exactamente lo que buscábamos cuando decidimos emprender en este proyecto. Sabemos que tenemos muchísima riqueza en nuestro país y el cacao mexicano es uno de los mejores del mundo. Sin embargo, no hemos encontrado una finca que nos garantice la calidad constante que ustedes merecen. Nuestro sueño más grande es tener nuestro propio chocolate 100% mexicano y esperamos lograrlo pronto.</p>
			<Header className="secondary-header" text="¿Qué contiene el chocolate de Cocoan?" />
			<p>Nuestro chocolate está hecho a partir de cinco ingredientes: cacao colombiano, manteca de cacao 100% natural, lecitina de soya, azúcar y vainilla. Recuerda que entre más alto el porcentaje de cacao, más bajo es el porcentaje de azúcar en el chocolate.</p>
			<p>Aún y cuando mantenemos un excelente control de calidad y limpieza dentro de nuestro taller, temperamos todas las variedades de chocolate en la misma máquina. Por favor considera que el producto final puede contener rastros de leche y/o nueces.</p>
			<Header text='¿Dónde puedo guardar mi chocolate?' className='secondary-header'/>
			<p>Para garantizar la calidad y textura de tu chocolate Cocoan, te recomendamos conservarlo en un ambiente fresco y seco. Si hace mucho calor y comienza a derretirse, puedes meterlo al refrigerador por 10-15 minutos. No recomendamos mantenerlo refrigerado porque pierde la intensidad del sabor.</p>
			<p>Los únicos productos que deberán mantenerse refrigerados son las fresas con chocolate y las trufas de chocolate. Todos los gelatos deberán mantenerse en congelación.</p>
			<Header text='¿Tienen chocolates veganos?' className='secondary-header'/>
			<p>¡Nuestros chocolates amargos son totalmente libre de lácteos! Esta es la selección:</p>
			<ul>
				<li>Chocolate 85% cacao.</li>
				<li>Chocolate 70% cacao.</li>
				<li>Chocolate 70% cacao con pistache, nuez de la india y almendras.</li>
				<li>Chocolate 65% cacao con almendras.</li>
				<li>Chocolate 65% cacao con frutos rojos.</li>
				<li>Chocolate 58% cacao con Goji Berry.</li>
				<li>Chocolate 58% cacao libre de azúcar.</li>
			</ul>
			<Header text='¿El chocolate contiene gluten?' className='secondary-header'/>
			<p>El cacao por naturaleza no contiene gluten. Sin embargo, algunos de nuestros chocolates se mezclan con ingredientes que sí contienen gluten. Busca la opción gluten free dentro de los productos en la sección de Comprar.</p>
			<Header text='¿Qué tiempo de vida tiene el chocolate?' className='secondary-header'/>
			<p>Nuestros chocolates son elaborados en pequeñas tandas para asegurar la frescura del producto, por lo que el tiempo de vida aproximado es de 3 a 4 meses.</p>
			<Header text='¿Tienen chocolate sin azúcar?' className='secondary-header'/>
			<p>¡Sí! Por el momento ofrecemos chocolate 58% cacao libre de azúcar. Está hecho con un edulcorante llamado Maltitol. Te recomendamos consumirlo con moderación porque el Maltitol tiene un efecto laxante si es consumido en exceso.</p>
		</div>
	</div>
}

export default HelpView;