import React, { useEffect, useState } from 'react';
import { CDN_URL } from '../ConfigPublic';
import { addCommas } from '../UtilPublic';
import { Image } from 'react-frontier';
import { Link } from 'react-router-dom';
import { ShopPayload } from '../ClassesPublic';
import classNames from 'classnames';

interface ProductProps{
	className?: string,
	style?: React.CSSProperties,
	product: ShopPayload["products"][0],
	placeholder?: boolean,
	placeholderActive?: boolean,
	link?: boolean,
}

var Product = (props: ProductProps)=>{
	const Element = (props.link!==false && !props.placeholder) ? Link : 'div';
	
	return <Element className={classNames('co product', {
		link: props.link!==false && !props.placeholder,
		placeholder: props.placeholder,
		active: props.placeholderActive
	}, props.className)} style={props.style} to={`/product/${props.product?.product_id}`}>
		{!props.placeholder ? <>
			<div className="image">
				<Image src={`${CDN_URL}/products/${props.product.product_id}/0.webp`} fallback='/placeholder_portrait.webp'/>
				{!!props.product.message && props.product.message.length>0 && (
					<div className="message">{props.product.message}</div>
				)}
			</div>
			<div className="info">
				<div className="name">{props.product.product_name}</div>
				<div className="price">
					{props.product.price_discounted ? <>
						<div className="final">
							${addCommas(props.product.price_discounted, true, true)}
						</div>
						<div className="discounted">
							${addCommas(props.product.price, true, true)}
						</div>
					</> : (
						`$${addCommas(props.product.price, true, true)}`
					)}
					<div className="size">{props.product.size}</div>
				</div>
			</div>
		</> : <>
			<div className="image"></div>
			<div className="info">
				<div className="name"></div>
				<div className="price"></div>
			</div>
		</>}
	</Element>
}

export default Product;
