import React, { useEffect, useState } from 'react';

interface ProgressProps{
	value: number,
	max?: number,
	min?: number,
	style?: React.CSSProperties,
	barStyle?: React.CSSProperties,
}

var SimpleProgress = (props: ProgressProps)=>{
	return <div className="co progress" style={props.style}>
		<div className="bar" style={{
			...props.barStyle,
			width: `${Math.max((props.min || 1), Math.min(100, Math.ceil((props.value*100)/(props.max || 100))))}%`
		}}></div>
	</div>
}

export default SimpleProgress;