import axios, { ResponseType } from 'axios';
import { APIResponse, CartItem, SerializedCartItem, ShopPayload, WebCategory, WebProduct, ShippingMethod, ShopVariables, WebData, InvoiceForm, SitePage } from './ClassesPublic';
import { CheckoutOrder, Location } from '@cocoan/components/Classes';

const ROOT_URL = process.env.REACT_APP_API_URL
const DEBUG = process.env.REACT_APP_DEBUG=='1';
const CDN_URL = process.env.REACT_APP_CDN_URL;
const IS_SANDBOX = process.env.REACT_APP_SANDBOX=='1';

const PAYFRONT_CONFIG = {
	client_id: process.env.REACT_APP_PAYFRONT_ID,
	public_key: process.env.REACT_APP_PAYFRONT_KEY,
}

const OPENPAY_CONFIG = {
	merchant_id: process.env.REACT_APP_OPENPAY_ID,
	api_key: process.env.REACT_APP_OPENPAY_KEY,
}

var CACHE : { [x: string]: APIResponse<any> } = {};

interface PostOptions{
	responseType?: ResponseType,
	endpoint?: string,
}

async function post<T=any>(endpoint: string, data?: any, opts?: PostOptions) : Promise<APIResponse<T>>{
	var options = {
		headers: {
			'token': '',
			'Content-Type': 'application/json'
		}
	}
	if(data instanceof FormData){
		options.headers['Content-Type'] = 'multipart/form-data';
	}

	try{
		var ENDPOINT = (opts && opts.endpoint) ? opts.endpoint : `${ROOT_URL}/public/${endpoint}`;
		if(DEBUG) console.log("POST "+ENDPOINT);
		var res = await axios.post(ENDPOINT, data, {
			...options,
			responseType: opts?.responseType || 'json'
		});
		if(DEBUG) console.log(` => Response: ${(parseInt(res.headers['content-length'])/1024).toFixed(2)} KB`);
		return res.data as APIResponse<T>;
	}catch(err){
		if(DEBUG) console.error(err);
		if(err && (err as any).response && (err as any).response.status===429){
			return {
				error: true,
				message: 'Se ha pasado la cantidad de intentos disponbiles.',
				code: 'API-RATE-429',
			}
		}
		return {
			error: true, 
			message: 'Hubo un error inesperado (API-LCL-1)',
			code: 'API-LCL-1',
		}
	}
};

var cachePost = async <T>(endpoint: string, key: string, data?: any) : Promise<APIResponse<T>>=>{
	if(CACHE[key]) return CACHE[key] as APIResponse<T>;
	var res = await post(endpoint, data);
	if(!res.error) CACHE[key] = res;
	return res;
}

var getVariables = ()=>{
	return cachePost<WebData>('webdata', 'webdata');
}

var getAllCategories = ()=>{
	return cachePost<WebCategory[]>('categories', 'categories');
}

var getCategoryProducts = (category_id: number)=>{
	return post('categories/products', { category_id });
}

var getShopData = (categories: number[], query: string, offset: number=0, count: number=50)=>{
	return post<ShopPayload>('shop', { categories, query, offset, count })
}

var getProduct = (product_id: number, images: boolean=false, categories: boolean=false, metadata: boolean=false, related: boolean=false)=>{
	return post<WebProduct>('products/get', { product_id, images, related, categories, metadata });
}

var getCartItem = (product_id: number, quantity: number)=>{
	return post<CartItem>('cart/item', { product_id, quantity });
}

var getCart = (cart: SerializedCartItem[])=>{
	return post<CartItem[]>('cart/get', { cart });
}

var getShippingMethods = ()=>{
	return post<ShippingMethod[]>('checkout/methods/shipping');
}

var createOrder = (email: string, shipping_method: number, cart: SerializedCartItem[], newsletter: boolean=false)=>{
	return post<CheckoutOrder>('checkout/create', { email, shipping_method, cart, newsletter });
}

var getCheckoutOrder = (order_hash: string)=>{
	return post<CheckoutOrder>('checkout/get', { order_hash });
}

interface CheckoutShipping{
	shipping_method: number,
	owner_name: string,
	owner_last_name: string,
	owner_phone: string,
	shipping_date: number,
	gift: {
		gift_name: string,
		gift_last_name: string,
		gift_message: string,
		gift_phone: string,
	},
	location: Location
}
var sendCheckoutShipping = (order_hash: string, data: CheckoutShipping)=>{
	return post<{ paid: boolean, total: number, shipping_cost: number }>('checkout/delivery', { order_hash, ...data });
}

var getShippingClosedDates = (shipping_type: number)=>{
	return cachePost<number[]>('checkout/methods/days', `SHCLD-${shipping_type}`, { shipping_type });
}

var sendCardPayment = (order_hash: string, token: string, digits: string, bin: string)=>{
	return post<{ redirect: string }>('checkout/payment/card', { order_hash, token, digits, bin });
}

var sendCardPaymentOpenpay = (order_hash: string, token: string, device: string, digits: string, bin: string)=>{
	return post<{ redirect: string }>('checkout/payment/card/openpay', { order_hash, device, token, digits, bin });
}

var sendCreatePayment = (order_hash: string, method_id: number)=>{
	return post('checkout/payment/other', { order_hash, method_id });
}

var getOrderData = (order_hash: string)=>{
	return post('orders/get', { order_hash });
}

var getPaymentInstructions = (order_hash: string)=>{
	return post('orders/instructions', { order_hash });
}

var createOrderInvoice = (tax_data: InvoiceForm, location: Location)=>{
	return post('orders/invoice/create', { ...tax_data, location });
}

var searchOrder = (order_id: number, email: string)=>{
	return post('orders/search', { order_id , email });
}

var getCheckoutLink = (token: string)=>{
	return post<{ order_hash: string }>('checkout/link', { token });
}

var getCheckoutResponse = (order_hash: string, payment_id: number)=>{
	return post<{ paid: boolean, message: string, redirect_url: string }>('checkout/response', { order_hash, payment_id });
}

var getPage = (page_url: string)=>{
	return post<SitePage>('pages/get', {page_url}); 
}

export default {
	IS_SANDBOX,
	OPENPAY_CONFIG,
	PAYFRONT_CONFIG,
	createOrder,
	createOrderInvoice,
	getAllCategories,
	getCart,
	getCartItem,
	getCategoryProducts,
	getCheckoutOrder,
	getCheckoutLink,
	getCheckoutResponse,
	getOrderData,
	getPage,
	getPaymentInstructions,
	getProduct,
	getShippingClosedDates,
	getShippingMethods,
	getShopData,
	getVariables,
	searchOrder,
	sendCardPayment,
	sendCardPaymentOpenpay,
	sendCheckoutShipping,
	sendCreatePayment,
}